import {
  Component,
  ElementRef,
  ViewChild,
  Inject,
  HostBinding,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { PLUrlsService } from '@root/src/lib-components';
import { PLUserPhotoService } from '@root/src/lib-components/pl-user-photos/pl-user-photo.service';

@Component({
  selector: 'pl-provider-profile-poster-dialog',
  templateUrl: './pl-provider-profile-poster-dialog.component.html',
  styleUrls: ['./pl-provider-profile-poster-dialog.component.less'],
})
export class PLProviderProfilePosterDialogComponent {
  @ViewChild('posterContent') posterContent: ElementRef;
  @ViewChild('spanishContent') spanishContent: ElementRef;
  @HostBinding('class') hostClass = 'profile-poster-dialog';
  providers: any[] = [];
  userPictures: { [key: string]: SafeUrl } = {};
  loading: boolean = true;
  roomUrl: string;

  constructor(
    public dialogRef: MatDialogRef<PLProviderProfilePosterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { providers: any[] },
    private plUserPhotoService: PLUserPhotoService,
    private plUrls: PLUrlsService,
  ) {
    this.providers = data.providers;
    this.roomUrl = this.plUrls.urls.roomFE;
    if (this.providers.length > 0) {
      this.loadProviderImages();
    }
    // Set dialog size
    this.dialogRef.updateSize('900px', '700px');
  }

  private loadProviderImages() {
    let loadedImages = 0;
    this.providers.forEach(provider => {
      this.plUserPhotoService
        .getImageDataWithMizar({ uuid: provider.user })
        .subscribe((res: SafeUrl) => {
          this.userPictures[provider.user] = res;
          loadedImages++;
          if (loadedImages === this.providers.length) {
            this.loading = false;
          }
        });
    });
  }

  getPageCount(): number {
    return this.providers.reduce((count, provider) => {
      return count + this.hasSpanishSupport(provider) ? 2 : 1;
    }, 0);
  }

  getProviderImage(provider: any): SafeUrl {
    return (
      this.userPictures[provider.user] || this.plUserPhotoService.USER_ICON_SVG
    );
  }

  getDisplayName(provider: any): string {
    if (!provider?.preferred_name) {
      return `${provider?.first_name} ${provider?.last_name}`;
    }
    return provider.preferred_name;
  }

  getProviderTitle(provider: any, spanishEnabled = false): string {
    if (!provider?.provider_type_details?.length) {
      return '';
    }

    const spanish = {
      'School Psychologist': ' Psicólogo/a escolar',
      'Speech-Language Pathologist': 'Terapeuta del habla y lenguaje',
      'Occupational Therapist': 'Terapeuta ocupacional',
      'Mental Health Professional': 'Profesional de la salud mental',
    };

    return provider.provider_type_details
      .map((type: any) =>
        spanishEnabled ? spanish[type.long_name] : type.long_name,
      )
      .join(', ');
  }

  getSpecialties(provider: any): any[] {
    return provider?.specialties || [];
  }

  getLanguages(provider: any): any[] {
    return provider?.languages || [];
  }

  hasSpanishSupport(provider: any): boolean {
    return provider.languages?.some(
      (lang: { code: string }) => lang.code === 'es',
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  async onSavePDF(): Promise<void> {
    try {
      const englishContent = this.posterContent.nativeElement;
      const spanishContent = this.spanishContent?.nativeElement;

      // Create PDF with US Letter size
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'letter', // 8.5" x 11"
      });

      // Add margins (0.25" on each side for more content space)
      const margin = 0.25;
      const contentWidth = 8.5 - 2 * margin;

      // Generate English pages
      const englishCanvas = await html2canvas(englishContent, {
        scale: 2, // Higher quality
        useCORS: true,
        logging: false,
        backgroundColor: '#ffffff',
        width: 768, // 8 inches * 96 DPI
        height: 1056, // 11 inches * 96 DPI
      });

      const englishHeight =
        (contentWidth * englishCanvas.height) / englishCanvas.width;
      const englishYPosition =
        englishHeight < 11 - 2 * margin
          ? margin + (11 - 2 * margin - englishHeight) / 2
          : margin;

      pdf.addImage(
        englishCanvas.toDataURL('image/png'),
        'PNG',
        margin,
        englishYPosition,
        contentWidth,
        englishHeight,
        '',
        'FAST',
      );

      // Generate Spanish pages if there are providers with Spanish support
      if (
        spanishContent &&
        this.providers.some(p => this.hasSpanishSupport(p))
      ) {
        const spanishCanvas = await html2canvas(spanishContent, {
          scale: 2,
          useCORS: true,
          logging: false,
          backgroundColor: '#ffffff',
          width: 768,
          height: 1056,
        });

        const spanishHeight =
          (contentWidth * spanishCanvas.height) / spanishCanvas.width;
        const spanishYPosition =
          spanishHeight < 11 - 2 * margin
            ? margin + (11 - 2 * margin - spanishHeight) / 2
            : margin;

        // Add new pages for Spanish content
        pdf.addPage();
        pdf.addImage(
          spanishCanvas.toDataURL('image/png'),
          'PNG',
          margin,
          spanishYPosition,
          contentWidth,
          spanishHeight,
          '',
          'FAST',
        );
      }

      const fileName = `providers-profile-poster.pdf`;
      pdf.save(fileName);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  }
}
