<div class="heading">
  <h2>Your profile {{ getPageCount() === 1 ? 'poster' : 'posters' }}</h2>
  <button type="button" mat-icon-button (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<pl-spinner [loading]="loading" size="large"></pl-spinner>

<ng-template #posterTemplate let-provider="provider" let-isSpanish="isSpanish">
  <div class="provider-poster">
    <div class="provider-header">
      <div class="profile-picture-container">
        <div class="decorative-rectangle red"></div>
        <div class="decorative-rectangle blue"></div>
        <div class="profile-picture">
          <img
            [src]="getProviderImage(provider)"
            [alt]="
              isSpanish ? 'Foto del proveedor' : 'Provider profile picture'
            "
          />
        </div>
      </div>
      <div class="name-section">
        <div class="greeting">
          <strong>{{ isSpanish ? '¡Hola!' : 'Hello!' }}</strong>
          {{ isSpanish ? 'Mi nombre es' : 'My name is' }}
        </div>
        <div class="name">
          {{ getDisplayName(provider) }}
        </div>
        <div class="provider-title">
          {{ getProviderTitle(provider, isSpanish) }}
        </div>
      </div>
    </div>

    <div class="quick-facts">
      <div class="post-it-note">
        <img
          [src]="
            isSpanish
              ? 'assets/sticky-spanish.png'
              : 'assets/sticky-english.png'
          "
          [alt]="isSpanish ? 'Un poco sobre mí' : 'A little bit about me'"
          class="about-me-note"
        />
      </div>
      <div class="qa-section">
        <div class="qa-item">
          <div class="question">
            {{
              isSpanish
                ? provider.ice_breaker_es_question_1
                : provider.ice_breaker_question_1
            }}
          </div>
          <div class="answer">
            {{
              isSpanish
                ? provider.ice_breaker_es_answer_1
                : provider.ice_breaker_answer_1
            }}
          </div>
        </div>
        <div class="qa-item">
          <div class="question">
            {{
              isSpanish
                ? provider.ice_breaker_es_question_2
                : provider.ice_breaker_question_2
            }}
          </div>
          <div class="answer">
            {{
              isSpanish
                ? provider.ice_breaker_es_answer_2
                : provider.ice_breaker_answer_2
            }}
          </div>
        </div>
        <div class="qa-item">
          <div class="question">
            {{
              isSpanish
                ? provider.ice_breaker_es_question_3
                : provider.ice_breaker_question_3
            }}
          </div>
          <div class="answer">
            {{
              isSpanish
                ? provider.ice_breaker_es_answer_3
                : provider.ice_breaker_answer_3
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="poster-footer">
      <pl-icon
        class="side-nav-logo"
        svg="logo-color-no-tm"
        [width]="'155'"
        [height]="'40'"
        [verticalAlign]="'-5px'"
      ></pl-icon>
      <div class="room-link">
        <div class="label">
          {{ isSpanish ? 'ENLACE DE LA SALA' : 'ROOM LINK' }}
        </div>
        <div class="url">{{ roomUrl }}/{{ provider?.username }}</div>
      </div>
    </div>
  </div>
</ng-template>

<mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="center center" class="pages-container">
    <!-- English Versions -->
    <div #posterContent class="poster-content" [class.loaded]="!loading">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div *ngFor="let provider of providers">
          <ng-container
            *ngTemplateOutlet="
              posterTemplate;
              context: { provider: provider, isSpanish: false }
            "
          ></ng-container>
        </div>
      </div>
    </div>

    <!-- Spanish Versions -->
    <div #spanishContent class="poster-content" [class.loaded]="!loading">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div *ngFor="let provider of providers">
          <div *ngIf="hasSpanishSupport(provider)">
            <ng-container
              *ngTemplateOutlet="
                posterTemplate;
                context: { provider: provider, isSpanish: true }
              "
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onClose()">Close</button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="loading"
    (click)="onSavePDF()"
  >
    Download PDF
  </button>
</mat-dialog-actions>
