<div class="card pl-provider-details-basic-information">
  <div class="header">
    <h3>Basic information</h3>
  </div>
  <table *ngIf="provider">
    <tbody>
      <tr class="name" *ngIf="!!name">
        <th scope="row">Name</th>
        <td>{{ name }}</td>
      </tr>
      <tr class="pronunciation" *ngIf="!!pronunciation">
        <th scope="row">Name pronunciation</th>
        <td>{{ pronunciation }}</td>
      </tr>
      <tr class="birthday" *ngIf="!!birthDay && userType !== 'CUSTOMER'">
        <th scope="row">Birthday</th>
        <td>{{ birthDay }}</td>
      </tr>
      <tr class="timezone" *ngIf="!!timeZone">
        <th scope="row">Timezone</th>
        <td>{{ timeZone }}</td>
      </tr>
      <tr *ngIf="useProviderPosters">
        <th scope="row">Provider poster</th>
        <td class="poster">
          <div [ngClass]="getPosterIcon()" class="icon-wrapper">
            <mat-icon>{{ getPosterIcon() }}</mat-icon>
          </div>
          {{ posterComplete ? 'Complete' : 'Incomplete' }}
          <button
            *ngIf="posterComplete"
            mat-flat-button
            color="basic"
            (click)="openPosterDialog()"
          >
            Preview
            <mat-icon>open_in_new</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
